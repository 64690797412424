
import { mapWritableState } from 'pinia'
import { useUiStore } from '@/stores/ui'

export default {
  name: 'PopupRegistrationRequired',
  computed: {
    ...mapWritableState(useUiStore, [
      'registrationRequired',
      'weReceivedYourRequest',
    ]),
  },
  beforeDestroy() {
    this.registrationRequired = false
    this.weReceivedYourRequest = false
  },
  methods: {
    closeModal() {
      this.registrationRequired = false
    },
    closeModalResive() {
      this.weReceivedYourRequest = false
    },
    toLogin() {
      try {
        this.$router.push({
          path: this.localePath('auth-authentication'),
        })
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') throw error
      }
    },
  },
}
